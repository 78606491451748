<template>
	<div class="main-content">
		<agri-input-modal :agri-input="agriInput" :show="is.showing.agriInputModal" @close="closeAgriInputModal" />
		<evidence-modal :evidence="evidence" :show="is.showing.evidenceModal" @close="closeEvidenceModal" />
		<div>
			<ktv-breadcrumb
				:folder="$t('Premium Points', 'Premium Points')"
				:page="$t('Report', 'Report')"
				:title="$t('Agri Input Handover Report', 'Agri Input Handover Report')"
			/>
			<b-row>
				<b-col md="12 mb-30">
					<b-row class="mb-4">
						<b-col>
							<router-link v-b-tooltip.hover :to="{ name: 'Premium Points Disburse List' }">
								<ktv-back :label="$t('Back to disburse premium list', 'Back to disburse premium list')" />
							</router-link>
						</b-col>
					</b-row>
					<div>
						<b-row>
							<b-col>
								<h2>{{ $t("Agri Input Handover Report", "Agri Input Handover Report") }} ({{ $route.params.id }})</h2>
							</b-col>
						</b-row>
						<div class="mt-32">
							<ktv-table
								:columns="columns"
								:filter="false"
								:is-loading="is.loading"
								:line-numbers="false"
								:rows="agriInputHandoverReportData"
								:search-enabled="false"
								:search-custom-enabled="false"
								:total-rows="agriInputHandoverReportTotalRows"
								min-height="250px"
								mode="remote"
								@on-page-change="onPageChange"
								@on-per-page-change="onPerPageChange"
								@on-sort-change="onSortChange"
							>
								<template #actionleft>
									<div class="d-flex">
										<ktv-button
											:disabled="!agriInputHandoverReportStatusType || agriInputHandoverReportStatusType !== 235"
											:text="$t('Export to PDF', 'Export to PDF')"
											:tooltip="
												!agriInputHandoverReportStatusType || agriInputHandoverReportStatusType !== 235
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											color="light"
											icon="download"
											@click="exportReport('pdf')"
										/>
										<ktv-button
											:disabled="!agriInputHandoverReportStatusType || agriInputHandoverReportStatusType !== 235"
											:text="$t('Export to Excel', 'Export to Excel')"
											:tooltip="
												!agriInputHandoverReportStatusType || agriInputHandoverReportStatusType !== 235
													? $t(
														'Unable to export, disburse status has not been closed',
														'Unable to export, disburse status has not been closed',
													)
													: ''
											"
											class="ml-2"
											color="light"
											icon="download"
											@click="exportReport('xlsx')"
										/>
									</div>
								</template>
								<template #columns="{ props }">
									{{
										props.column.label === "CVC Prepare Agri Input"
											? capitalize($t("CVC Prepare Agri input", "CVC Prepare Agri input"))
											: $t(props.column.label, props.column.label)
									}}
								</template>
								<template #rows="{ props }">
									<span v-if="props.column.field === 'button'">
										<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
											<template #button-content>
												<img :src="icons.dropdown" />
											</template>
											<b-dropdown-item @click="showAgriInputModal(props.row)">
												<img :src="icons.view" class="imgicon" />{{ $t("Detail Agri Input Item", "Detail Agri Input Item") }}
											</b-dropdown-item>
										</b-dropdown>
									</span>
									<span v-if="props.column.field === 'number'">{{ props.row.originalIndex + (params.page - 1) * params.limit + 1 }}</span>
									<span v-else-if="props.column.field === 'farmerUid'">
										{{ props.row.farmerUid ? props.row.farmerUid : "-" }}
									</span>
									<span v-else-if="props.column.field === 'farmerName'">{{ props.row.farmerName ? props.row.farmerName : "-" }}</span>
									<span v-else-if="props.column.field === 'productQuantity'">
										{{ props.row.agriInputItem ? idrFilter(props.row.agriInputItem.length) : "-" }}
									</span>
									<span v-else-if="props.column.field === 'instructionToCvc'">
										{{ props.row.instructionToCvc ? props.row.instructionToCvc : "-" }}
									</span>
									<span v-else-if="props.column.field === 'cvcPrepareAgriInput'">
										{{ props.row.cvcPrepareAgriInput ? props.row.cvcPrepareAgriInput : "-" }}
									</span>
									<span v-else-if="props.column.field === 'agriInputPickup'">
										{{ props.row.agriInputPickup ? props.row.agriInputPickup : "-" }}
									</span>
									<span v-else-if="props.column.field === 'agriInputReceived'">
										{{ props.row.agriInputReceived ? props.row.agriInputReceived : "-" }}
									</span>
									<span v-else-if="props.column.field === 'cvcName'">
										{{ props.row.cvcName ? props.row.cvcName : "-" }}
									</span>
									<span v-else-if="props.column.field === 'evidence'">
										<ktv-button :text="$t('View', 'View')" color="light" icon="view" @click="showEvidenceModal(props.row)" />
									</span>
								</template>
							</ktv-table>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import { KtvBack, KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import AgriInputModal from "./modals/_AgriInput"
	import EvidenceModal from "./modals/_Evidence"

	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		dropdown: dropdownIcon,
		view: viewIcon,
	}

	export default {
		name: "AgriInputHandoverReport",
		metaInfo: {
			title: "View Agri Input Handover Report",
		},
		components: { AgriInputModal, EvidenceModal, KtvBack, KtvButton, KtvTable },
		data() {
			return {
				agriInput: [],
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "number",
						html: true,
						label: "Number",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerUid",
						label: "Farmer UID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "farmerName",
						label: "Farmer Name",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "productQuantity",
						html: true,
						label: "Number Agri Input Item",
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "instructionToCvc",
						label: "Instruction to CVC",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "cvcName",
						label: "CVC Name",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "cvcPrepareAgriInput",
						label: "CVC Prepare Agri Input",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "agriInputPickup",
						label: "Agri Input Ready for Pick Up",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "agriInputReceived",
						label: "Agri Input Received by Farmers",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "evidence",
						html: true,
						label: "Evidence",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				evidence: {},
				icons: icons,
				is: {
					loading: false,
					showing: {
						agriInputModal: false,
						evidenceModal: false,
					},
				},
				params: {
					disburseId: parseInt(String(this.$route.params.id).replaceAll("PRE", ""), 10) || null,
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				agriInputHandoverReportData: "PREMIUMPOINT/agriInputHandoverReportData",
				agriInputHandoverReportStatusType: "PREMIUMPOINT/agriInputHandoverReportStatusType",
				agriInputHandoverReportTotalRows: "PREMIUMPOINT/agriInputHandoverReportTotalRows",
			}),
		},
		watch: {
			"$route.params.id": {
				handler(newValue, oldValue) {
					if (newValue) {
						if (newValue !== oldValue) {
							this.getData()
						}
					} else {
						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Please make sure to input valid disburse id", "Please make sure to input valid disburse id"),
							"error",
						)

						this.$router.push({
							name: "Premium Points Disburse List",
						})
					}
				},
				immediate: true,
			},
		},
		beforeDestroy() {
			this.resetAgriInputHandoverReport()
			this.evidence = Object.assign({})
		},
		methods: {
			...mapActions({
				getAgriInputHandoverReport: "PREMIUMPOINT/getAgriInputHandoverReport",
				getAgriInputHandoverReportEvidence: "PREMIUMPOINT/getAgriInputHandoverReportEvidence",
				getReportExport: "PREMIUMPOINT/getReportExport",
				resetAgriInputHandoverReport: "PREMIUMPOINT/resetAgriInputHandoverReport",
			}),
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			closeAgriInputModal() {
				this.is.showing = {
					...this.is.showing,
					agriInputModal: false,
				}

				this.agriInput = []
			},
			closeEvidenceModal() {
				this.is.showing = {
					...this.is.showing,
					evidenceModal: false,
				}

				this.evidence = Object.assign({})
			},
			downloadFile(url, fileName) {
				fetch(url)
					.then((response) => response.blob())
					.then((blob) => {
						const link = document.createElement("a")
						link.href = URL.createObjectURL(blob)
						link.download = fileName
						link.click()
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						this.$swal(err.name, errorMessage, "error")
					})
			},
			exportReport(extension = "pdf") {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getReportExport({
					disburseId: this.params.disburseId,
					extension: extension,
					type: "agriInputHandoverReport",
				})
					.then((agriInputHandoverReport) => {
						agriInputHandoverReport.forEach(({ fileName, url }) => {
							const fileExtension = fileName.split(".").pop()

							const replacedFileName = `${this.$t("Agri Input Handover Report", "Agri Input Handover Report")} (${
								this.$route.params.id
							}).${fileExtension}`
							this.downloadFile(url, replacedFileName)
						})

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getAgriInputHandoverReport(this.params)
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getDate(timestamp) {
				const date = new Date(timestamp)

				return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showAgriInputModal({ agriInputItem }) {
				this.agriInput = agriInputItem

				this.is.showing = {
					...this.is.showing,
					agriInputModal: true,
				}
			},
			showEvidenceModal({ farmerUid = null }) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getAgriInputHandoverReportEvidence({
					disburseId: this.params.disburseId,
					farmerId: farmerUid,
				})
					.then((evidence) => {
						const image = evidence.results?.ImageFarmer
						const number = evidence.results?.OtpNumber

						if (image) {
							this.evidence = Object.assign({}, evidence.results)

							this.is.showing = {
								...this.is.showing,
								evidenceModal: true,
							}
						} else if (number) {
							this.$swal(this.$t("PIN", "PIN"), `${this.$t("Using PIN", "Using PIN")}: <b>${number}</b>`, "info")
						} else {
							this.$swal(this.$t("Failed", "Failed"), this.$t("Evidence data not found", "Evidence data not found"), "error")
						}

						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
