<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" :title="$t('Evidence', 'Evidence')" no-close-on-backdrop no-close-on-esc size="lg" @close="close()">
			<template #content>
				<div class="p-3">
					<b-card no-body>
						<b-list-group flush>
							<b-card-img
								v-if="evidence.ImageFarmer"
								:alt="`${$t('Evidence', 'Evidence')} ${$t('Image', 'Image')}`"
								:src="evidence.ImageFarmer"
								bottom
								class="p-3"
							></b-card-img>
						</b-list-group>
					</b-card>
				</div>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Close', 'Close')" color="light" icon="close" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvModal } from "@/components"

	export default {
		name: "PremiumPointsReportEvidence",
		components: { KtvButton, KtvModal },
		props: {
			evidence: {
				default: () => {},
				required: true,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				is: {
					showingModal: false,
				},
			}
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		beforeDestroy() {
			this.close()
		},
		methods: {
			close() {
				this.$emit("close")
			},
		},
	}
</script>

<style lang="sass" scoped>
	.card
		box-shadow: none
</style>
