<template>
	<div class="main-content">
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Detail Agri Input Item', 'Detail Agri Input Item')"
			no-close-on-backdrop
			no-close-on-esc
			size="lg"
			@close="close()"
		>
			<template #content>
				<div class="p-3">
					<ktv-table
						id="agri-input"
						:columns="columns"
						:filter="false"
						:is-loading="is.loading"
						:line-numbers="false"
						:rows="filteredData"
						:search-enabled="false"
						:search-on-enter="false"
						:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
						:total-rows="agriInput ? filteredData.length : 0"
						min-height="250px"
						mode="client"
						@on-search="onSearch"
					>
						<template #columns="{ props }">
							{{ $t(props.column.label, props.column.label) }}
						</template>
						<template #rows="{ props }">
							<span v-if="props.column.field === 'productId'">
								{{ props.row.productId ? props.row.productId : "-" }}
							</span>
							<span v-else-if="props.column.field === 'productName'">{{ props.row.productName ? props.row.productName : "-" }}</span>
							<span v-else-if="props.column.field === 'qty'">{{ props.row.qty ? props.row.qty : "-" }}</span>
						</template>
					</ktv-table>
				</div>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Close', 'Close')" color="light" icon="close" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { filter } from "underscore"
	import { KtvButton, KtvModal, KtvTable } from "@/components"

	export default {
		name: "PremiumPointsReportAgriInputItem",
		components: { KtvButton, KtvModal, KtvTable },
		props: {
			agriInput: {
				default: () => [],
				required: true,
				type: Array,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				columns: [
					{
						field: "productId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "productName",
						label: "Agri Input Item",
						tdClass: "text-center",
						thClass: "text-center",
						width: "250px",
					},
					{
						field: "qty",
						html: true,
						label: "Quantity",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
				],
				is: {
					loading: false,
					showingModal: false,
				},
				searchText: null,
			}
		},
		computed: {
			filteredData() {
				return this.searchText
					? filter(this.agriInput, (product) => {
						const productName = String(product.productName).toLowerCase()
						const productId = String(product.productId).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return productName.indexOf(searchText) > -1 || productId.indexOf(searchText) > -1
					})
					: this.agriInput
			},
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		beforeDestroy() {
			this.close()
		},
		methods: {
			close() {
				this.$emit("close")
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
		},
	}
</script>

<style lang="sass" scoped>
	.card
		box-shadow: none
</style>
